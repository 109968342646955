import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './css/Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [navbarTransparent, setNavbarTransparent] = useState(true);
    const [language, setLanguage] = useState('en');

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 1300) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    const location = useLocation();
    const navigate = useNavigate();
    const isHome = location.pathname === '/' || location.pathname === '/fr/';

    const changeNavbarTransparency = () => {
        if (isHome && window.scrollY >= 100) {
            setNavbarTransparent(false);
        } else {
            setNavbarTransparent(true);
        }
    };

    const toggleLanguage = () => {
        const newLanguage = language === 'en' ? 'fr' : 'en';
        setLanguage(newLanguage);

        let newPath;
        if (newLanguage === 'fr') {
            newPath = location.pathname.startsWith('/fr') ? location.pathname : `/fr${location.pathname}`;
        } else {
            newPath = location.pathname.replace('/fr', '');
        }

        navigate(newPath);
    };

    useEffect(() => {
        showButton();
        if (isHome) {
            window.addEventListener('scroll', changeNavbarTransparency);
        }
        setLanguage(location.pathname.startsWith('/fr') ? 'fr' : 'en');
        return () => {
            window.removeEventListener('scroll', changeNavbarTransparency);
        };
    }, [isHome, location.pathname]);

    window.addEventListener('resize', showButton);

    const getLocalizedPath = (path) => {
        return language === 'fr' ? `/fr${path}` : path;
    };

    return (
        <nav className={`navbar ${isHome ? (navbarTransparent ? 'transparent' : '') : ''}`}>
            <div className="navbar-container">
                <Link to={getLocalizedPath("/")} className="navbar-logo">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/logos/${isHome && navbarTransparent ? 'hcm_white.png' : 'hcm_blue.png'}`}
                        alt="Logo"
                        height='50px'
                        width='300px'
                        className="navbar-logo-image"
                    />
                </Link>

                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>

                <ul className={click ? 'nav-menu active nav-menu-right' : 'nav-menu nav-menu-right'}>
                    <li className="nav-item">
                        <Link to={getLocalizedPath('/about-us')} className="nav-links" onClick={closeMobileMenu}>
                            {language === 'en' ? 'About Us' : 'À propos de nous'}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={getLocalizedPath('/your-team-heroics')} className="nav-links" onClick={closeMobileMenu}>
                            {language === 'en' ? 'Your Team Heroics' : "L'équipe Heroics"}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={getLocalizedPath('/investment-solutions')} className="nav-links" onClick={closeMobileMenu}>
                            {language === 'en' ? 'Investment Solutions' : 'Solutions d\'investissement'}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={getLocalizedPath('/alternative-solutions')} className="nav-links" onClick={closeMobileMenu}>
                            {language === 'en' ? 'Alternative Solutions' : 'Solutions alternatives'}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={getLocalizedPath('/join-us')} className="nav-links" onClick={closeMobileMenu}>
                            {language === 'en' ? 'Join Us' : 'Rejoignez-nous'}
                        </Link>
                    </li>
                    <li className="nav-item language-toggle-mobile">
                        <div className="nav-links" onClick={toggleLanguage}>
                            <img 
                                src={language === 'en' ? `${process.env.PUBLIC_URL}/images/logos/france.png` : `${process.env.PUBLIC_URL}/images/logos/uk.png`}
                                alt={language === 'en' ? 'Français' : 'English'}
                                height='30px'
                                width='30px'
                            />
                            <span>{language === 'en' ? 'Français' : 'English'}</span>
                        </div>
                    </li>
                </ul>

                <div className="language-toggle" onClick={toggleLanguage}>
                    <img 
                        src={language === 'en' ? `${process.env.PUBLIC_URL}/images/logos/france.png` : `${process.env.PUBLIC_URL}/images/logos/uk.png`}
                        alt={language === 'en' ? 'Français' : 'English'}
                        height='30px'
                        width='30px'
                    />
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
