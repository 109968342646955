import React, { useEffect, useRef } from 'react';
import '../css/YourTeamHeroics.css';
import Footer from '../footer';

const YourTeamHeroics = () => {
  // Code CSS pour le masque du logo
  const logoMaskStyle = {
    display: 'inline-block',
    width: '100%',
    maxWidth: '500px',
    height: 'auto',
    WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
    maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
    WebkitMaskRepeat: 'no-repeat',
    maskRepeat: 'no-repeat',
    WebkitMaskPosition: 'center',
    maskPosition: 'center',
    WebkitMaskSize: 'contain',
    maskSize: 'contain',
    margin: '0 auto'
  };

  // Code permettant de faire apparaitre les éléments au scroll
  const teamMemberRefs = useRef([]);

  useEffect(() => {
    
    const elements = teamMemberRefs.current;

    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-inViewport');
        } else {
          // entry.target.classList.remove('is-inViewport');
          // A DECOMMENTER POUR AVOIR LES ANIMATIONS A L'INFINI
        }
      });
    }, options);

    elements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    // Classe principale de la page Your Team Heroics
    <div className="your-team">
      {/* // Classe header comprenant l'image de fond */}
      <div className="header">
        <img src={`${process.env.PUBLIC_URL}/images/bg_pages/your_team_heroics.jpg`}  className="background-image" alt="background_image" />
      </div>

      {/* // Contenu de la page */}
      <div className="content-team">

        {/* // Section de description de la page en haut */}
        <div className='section-top'>
          <h2 className="section-title font-bold">L'équipe <span class="highlight">Heroics</span></h2>
          <p className="section-description">
          Chez Heroics Capital Monaco, nous sommes fiers de notre équipe de professionnels hautement qualifiés et passionnés, chacun apportant une richesse d'expérience et d'expertise dans leurs domaines respectifs. Notre équipe dévouée est l'épine dorsale de notre entreprise, conduisant notre mission d'excellence et notre engagement à fournir des solutions financières supérieures à nos clients.
          <br/><br/>
          Découvrez les profils de nos membres clés, dont les connaissances collectives et le dévouement inébranlable contribuent à notre succès sur les marchés financiers. Leur engagement et leur passion non seulement propulsent Heroics Capital Monaco en avant, mais inspirent également la confiance de nos précieux clients.

          </p>
        </div>

        <div className="central-image-container">
          <img src={`${process.env.PUBLIC_URL}/images/team_photos/founders.jpg`} alt="Team Heroics" className="central-image" />
          <div className="hotspots">
            {[
              { top: '65%', left: '18%', name: 'Julien BAPTISTE', role: 'CEO & Partner Heroics Capital Monaco' },
              { top: '57%', left: '37%', name: 'Jonathan NIDDAM', role: 'Head of Research, Trading & Co-Foundateur' },
              { top: '50%', left: '58%', name: 'Alexandre TRAMINI', role: 'Head of Distribution & Co-Foundateur' },
              { top: '55%', left: '85%', name: 'Cyrille NAHABEDIAN', role: 'CEO Heroics Capital Partners & Co-Foundateur' },
            ].map((hotspot, index) => (
              <div
                key={index}
                className="hotspot"
                style={{ top: hotspot.top, left: hotspot.left }}
              >
                <div className="tooltip">
                  <strong className="tooltip-name">{hotspot.name}</strong>
                  <span className="tooltip-role">{hotspot.role}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* // Membres de l'équipe : team-member-container pour une photo à gauche et team-member-container reverse pour droite */}
        {/* // Julien BAPTISTE */}
        <div className="team-member-container">
          <div className="photo" ref={el => (teamMemberRefs.current[0] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/julien_baptiste.jpg`} alt="Julien BAPTISTE" />
          </div>

          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[1] = el)}>Julien BAPTISTE</h2>

            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[2] = el)}>CEO & Partner Heroics Capital Monaco</h3>

            <p className="member-description">
              Après une carrière d’athlète de haut niveau dans les sports de combat, Julien a démarré sa carrière en finance de marché à Luxembourg sur un desk de dérivés et produits structurés. Il est titulaire d’un Master en Finance de marché et ingénierie financière de Kedge Business School.
              <br/>Il rejoint le monde de la banque privée à Monaco chez CFM Indosuez après 5 années passées en banque d’investissement. Son dernier poste impliquait la responsabilité du département Capital Markets Solutions et de l’Active Advisory.

              <br/><br/>C’est en fin d’année 2023, fort de 13 années passées dans le secteur bancaire, qu’il décide de se consacrer pleinement à la création d’Heroics Capital Monaco, concrétisant ainsi son ambition entrepreneuriale.
              <br/><br/>Son ambition chez Heroics Capital Monaco : « Introduire en Principauté un nouveau modèle d’affaires dans le secteur du conseil en investissement, un modèle où les gérants et les clients alignent leurs intérêts dans un objectif de croissance de leur capital en partenariat avec la société de gestion ».
            </p>
          </div>
        </div>

        {/* // Cyrille NAHABEDIAN */}
        <div className="team-member-container reverse">
          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[3] = el)}>Cyrille NAHABEDIAN</h2>

            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[4] = el)}>CEO Heroics Capital Partners & Co-Foundateur</h3>

            <p className="member-description">
              Cyrille Nahabedian est Co-fondateur et Chief Executive Officer chez Heroics Capital Partners. Avec près de 20 ans d'expérience dans les marchés de capitaux européens, Cyrille supervise l'ensemble des activités de l’organisation. Il assure la mise en œuvre de la stratégie et rend compte des résultats clés aux actionnaires. En tant que CEO, il co-valide les décisions et recommandations d'investissement de l'entreprise, et s’implique activement dans les relations avec les clients clés. Il supervise également les principaux programmes de recrutement et de développement des talents au sein de l’organisation.
              <br/><br/>Avant de fonder Heroics Capital en janvier 2023, Cyrille a occupé des postes de direction au sein du Groupe Crédit Agricole IWM, notamment en tant que Global Head of Capital Markets Division, où il gérait la stratégie et les projets de marchés de capitaux à l’échelle mondiale. Il a également dirigé des équipes de trading en Europe, notamment sur les activités de dérivés.
              <br/><br/>Diplômé de Skema Business School avec un Master en Management des Marchés de Capitaux et titulaire d’un Master de Recherche en Marchés de Capitaux de l’Université Paris I Sorbonne, Cyrille continue de partager son expertise en tant qu’intervenant pour les Masters Professionnels à l’Ecole Supérieure de Commerce de Paris.
            </p>
          </div>

          <div className="photo" ref={el => (teamMemberRefs.current[5] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/cyrille_nahabedian.jpg`} alt="Cyrille NAHABEDIAN" />
          </div>
        </div>

        {/* // Alexandre TRAMINI */}
        <div className="team-member-container">
          <div className="photo" ref={el => (teamMemberRefs.current[6] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/alexandre_tramini.jpg`} alt="Alexandre TRAMINI" />
          </div>

          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[7] = el)}>Alexandre TRAMINI</h2>

            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[8] = el)}>Head of Distribution & Co-Foundateur</h3>

            <p className="member-description">
            En tant que co-fondateur et associé chez Heroics Capital, Alexandre Tramini met à profit son expertise en finance et son esprit entrepreneurial pour soutenir des startups innovantes et des entreprises sociales. Fort de plus de 17 ans d’expérience dans la banque d’investissement et la gestion de patrimoine, il a développé de solides compétences en finance d’entreprise et en marchés de capitaux, tout en acquérant une compréhension approfondie des besoins et des défis auxquels sont confrontés les clients fortunés et les family offices.
              <br/><br/>Auparavant, Alexandre a occupé le poste de responsable des ventes de solutions sur les marchés de capitaux pour l’Europe chez CA Indosuez Wealth Management, où il dirigeait une équipe de professionnels des ventes et conseillait les clients sur les stratégies de couverture et d’investissement dans diverses classes d’actifs. De 2014 à 2023, en collaboration avec ses partenaires, il a lancé une initiative innovante en matière de marchés de capitaux au sein de CA Indosuez Wealth Management, spécialisée dans le trading et le conseil. Cette initiative a permis de redynamiser l’offre de la banque privée en répondant aux besoins spécifiques des entrepreneurs, des family offices et des clients ultra-fortunés, tout en renforçant les synergies avec les solutions de banque d’investissement.
              <br/><br/>Alexandre est diplômé de Kedge Business School et détient deux diplômes de troisième cycle : un Master en ingénierie financière et un Master en droit bancaire et gestion de patrimoine.
            </p>
          </div>
        </div>

        {/* // Jonathan NIDDAM */}
        <div className="team-member-container reverse">
          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[9] = el)}>Jonathan NIDDAM</h2>

            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[10] = el)}>Head of Research, Trading & Co-Foundateur</h3>
            
            <p className="member-description">
            Jonathan Niddam est co-fondateur et responsable de la recherche et du trading chez Heroics Capital, au Luxembourg. Avec plus de dix ans d’expérience en finance, notamment dans le trading de devises (Forex) et d’options sur actions, il accompagne des investisseurs très fortunés sur différents marchés, comme les métaux précieux, les actions, les devises et les obligations, tout en gérant des portefeuilles d’investissements alternatifs.
              <br/><br/>En tant que responsable de la recherche et du trading chez Heroics Capital, il utilise des techniques de traitement de données avancées pour affiner et optimiser les stratégies d’investissement. Son expérience de près de dix ans chez Indosuez Wealth Management, où il s’est spécialisé dans les produits dérivés de gré à gré (OTC) et l’intégration de technologies, a profondément influencé sa façon de gérer les portefeuilles et les risques.
              <br/><br/>Jonathan est diplômé en finance computationnelle de l’EFREI Paris et a également travaillé chez Morgan Stanley, où il a développé ses compétences en dérivés actions. Son expertise lui permet de proposer des solutions sur mesure, garantissant que les stratégies de Heroics Capital restent réactives, innovantes et orientées vers l’analyse de données.
            </p>
          </div>

          <div className="photo" ref={el => (teamMemberRefs.current[11] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/jonathan_niddam.jpg`} alt="Jonathan NIDDAM" />
          </div>
        </div>

        {/* // Jean-Jacques MARTINENGHI */}
        <div className="team-member-container">
          <div className="photo" ref={el => (teamMemberRefs.current[12] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/jean_jacques_martinenghi.jpg`} alt="Jean-Jacques MARTINENGHI" />
          </div>

          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[13] = el)}>Jean-Jacques MARTINENGHI</h2>
            
            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[14] = el)}>Head Of Risk and Compliance</h3>
            
            <p className="member-description">
              Jean-Jacques Martinenghi a rejoint Heroics Capital Monaco en tant que Head of Risks and Compliance. Dans ce rôle, il est en charge de la gouvernance des risques et de la conformité, assurant l’alignement des activités de l’entreprise avec les réglementations locales et internationales. Il supervise l’élaboration et l’optimisation des processus de gestion des risques, en mettant en place des stratégies efficaces pour identifier, évaluer et atténuer les risques potentiels. Il veille également à l’application rigoureuse des politiques internes, garantissant une conformité stricte aux exigences légales et normatives du secteur.
              <br/><br/>Avant de rejoindre Heroics Capital Monaco, Jean-Jacques a acquis une solide expérience à l’Inspection Générale de LCL, où il a passé 3 ans à auditer et analyser des processus internes, renforçant ainsi sa compréhension des enjeux de conformité et de gestion des risques au sein du secteur bancaire et financier.
              <br/><br/>Diplômé de l’ECE Paris en tant qu’ingénieur spécialisé en ingénierie quantitative, Jean-Jacques se distingue par sa capacité à allier une approche analytique et proactive pour sécuriser les activités de l’entreprise tout en optimisant ses performances en matière de compliance.
            </p>
          </div>
        </div>

        {/* // Tom CHEBEAUX */}
        <div className="team-member-container reverse">
          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[15] = el)}>Tom CHEBEAUX</h2>
            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[16] = el)}>Cross Asset Sales Trader</h3>
            <p className="member-description">
              Tom Chebeaux a rejoint Heroics Capital Monaco en tant que Sales-Trader Cross-Asset. Dans ce rôle, il assiste les clients fortunés dans la construction et la gestion de leurs portefeuilles. Il collabore également avec une variété de clients, tels que des gestionnaires d'actifs, des banques privées et des conseillers en gestion de patrimoine, pour créer des solutions d'investissement sur mesure.
              <br/><br/>Avant de rejoindre Heroics Capital, il a acquis une expérience précieuse chez ODDO BHF CIB et CFM Indosuez Wealth Management, travaillant dans la vente Actions ainsi que dans la vente et la structuration Cross-Asset.
              <br/><br/>Diplômé de l'EDHEC Business School et titulaire d'un Master en Finance de marché de l'ESSEC Business School, Tom est passionné par la finance. Il se distingue par sa capacité à comprendre les besoins spécifiques de ses clients et à leur fournir des solutions parfaitement adaptées.
            </p>
          </div>

          <div className="photo" ref={el => (teamMemberRefs.current[17] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/tom_chebeaux.jpeg`} alt="Tom CHEBEAUX" />
          </div>
        </div>
      </div>

      {/* // Pre-footer avec vidéo en fond de logo */}
      <div className="bottom-section">
        <div className="logo_mask" style={logoMaskStyle}>
          <div className="video_holder">
            <video width="500" height="350" src="/videos/your_team_heroics.mp4" autoPlay muted loop playsInline />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default YourTeamHeroics;
