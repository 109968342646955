import React from 'react';
import '../css/JoinUs.css';
import Footer from '../footer';



const JoinUs = () => {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
    };
      
    return (
        // Classe principale de la page JoinUs
        <div className="join-us"> 
            
            {/* // Classe header comprenant l'image de fond */}
            <div className="header">
                <img src={`${process.env.PUBLIC_URL}/images/bg_pages/join_us.jpg`} className="background-image" alt="background_image" />
            </div>
            

            {/* // Classe container du contenu de la page */}
            <div className="container">
                {/* // Conteneur du texte à gauche de la page */}
                <div className="main-content">
                    {/* // Contenu texte à gauche de la page */}
                    <section className="section">
                        {/* // Titre du texte à gauche de la page */}
                        <h2 className="section-title font-bold">Join Heroics Capital </h2><br/>
                        
                        <h5 className="section-text">
                            At Heroics, we value innovation, collaboration, and a passion for finance. We believe in creating an environment where talented individuals can thrive and make a difference.
                            <br/><br/> Whether you're an experienced professional or just starting your career, we welcome spontaneous applications from individuals who are enthusiastic about finance and eager to contribute to our dynamic startup environment.
                            <br/><br/>We look forward to hearing from you and potentially welcoming you to our team ! 
                            <br/>
                            <br/>
                        </h5>

                        <h5 className="section-text">
                            Additionally, you can connect with us on LinkedIn and send us a direct message. Visit our LinkedIn page
                            here to stay updated on company news and job opportunities.
                        </h5>
                    </section>
                </div>
                
                {/* // Conteneur du texte à droite de la page */}
                <div className="sidebar">
                    <section className="section contact-form">
                        <h3 className="contact-title font-semibold">Contact us</h3><br/>

                        <h4 className="contact-info">
                            Le Panorama<br/> 57 rue Grimaldi <br/> 98000 Monaco <br/> <b>hr@heroics-capital.com</b>
                        </h4>
                        <br/>
                        <br/>

                        <img  
                            className="logoh block mx-auto" 
                            src={`${process.env.PUBLIC_URL}/images/logos/hc_mini_blue.png`}  
                            width='200px' 
                            alt="Logo"
                        />
                        
                        <h6 className="font-semibold">"Unusual actions or achievements, far greater than expected."</h6>
                    </section>
                </div>

            </div>

            {/* // Pre-footer vidéo */}
            <div className="bottom-section">
                <div class="logo_mask" style={logoMaskStyle}>
                    <div class="video_holder">
                        <video width="500px" height="350px" src="/videos/join_us.mp4" autoPlay muted loop playsInline />
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default JoinUs;
