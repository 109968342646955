import React from "react";
import "../../App.css";
import HeroSection from "../herosection.js";
import Footer from '../footer';
import '../css/Home.css';

function Home() {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
    };

    return (
        <>
                {/* Vidéo de la page */}
                <HeroSection />

                {/* Contenu de la page */}
                <div className="content-home">

                {/* Cartes de redirection */}
                <div class="card-container">

                    {/* À propos de nous */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>À propos de nous</h3></div>
                            <p>L'essence de l'ADN HEROICS.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/about-us'>
                            <p class="hover-underline-animation">À propos de notre identité →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/about_us.jpg`} alt="À propos de nous"/>
                            </a>
                        </div>
                    </div>
                    
                    {/* Votre équipe */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Votre équipe Heroics</h3></div>
                            <p>L'équipe à votre service.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/your-team-heroics'>
                            <p class="hover-underline-animation">À propos de notre groupe →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/your_team.jpg`} alt="Votre équipe"/>
                            </a>
                        </div>
                    </div>
                    
                    {/* Solutions d'investissement */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Solutions d'investissement</h3></div>
                            <p>Stratégies d'investissement de pointe.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/investment-solutions'>
                            <p class="hover-underline-animation">À propos de nos solutions d'investissement →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/investment_solutions.jpg`} alt="Solutions d'investissement"/>
                            </a>
                        </div>
                    </div>

                    {/* Solutions alternatives */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Solutions alternatives</h3></div>
                            <p>Explorez des voies innovantes.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/alternative-solutions'>
                            <p class="hover-underline-animation">À propos de nos solutions alternatives →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/alternative_solutions.jpg`} alt="Solutions alternatives"/>
                            </a>
                        </div>
                    </div>

                    {/* Rejoignez-nous */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Rejoignez-nous</h3></div>
                            <p>Rejoignez la famille Heroics.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/join-us'>
                            <p class="hover-underline-animation">À propos de nos opportunités →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/join_us.jpg`} alt="Rejoignez-nous"/>
                            </a>
                        </div>
                    </div>

                    {/* Emplacements */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Localisations</h3></div>
                            <p>Notre présence mondiale.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/locations'>
                            <p class="hover-underline-animation">À propos de notre présence →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/locations.jpg`} alt="Emplacements"/>
                            </a>
                        </div>
                    </div>
                </div>
            
            
            {/* Pré-pied de page avec vidéo en fond de logo */}
            <div className="bottom-section">
                <div className="logo_mask" style={logoMaskStyle}>
                    <div className="video_holder">
                        <video width="500px" height="350px" src="/videos/home.mp4" autoPlay muted loop playsInline />
                    </div>
                </div>
            </div>
            <Footer />
            </div>
        </>
    );
}

export default Home;