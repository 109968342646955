import React, { useEffect, useRef } from 'react';
import '../css/YourTeamHeroics.css';
import Footer from '../footer';

const YourTeamHeroics = () => {
  // Code CSS pour le masque du logo
  const logoMaskStyle = {
    display: 'inline-block',
    width: '100%',
    maxWidth: '500px',
    height: 'auto',
    WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
    maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
    WebkitMaskRepeat: 'no-repeat',
    maskRepeat: 'no-repeat',
    WebkitMaskPosition: 'center',
    maskPosition: 'center',
    WebkitMaskSize: 'contain',
    maskSize: 'contain',
    margin: '0 auto'
  };

  // Code permettant de faire apparaitre les éléments au scroll
  const teamMemberRefs = useRef([]);

  useEffect(() => {
    
    const elements = teamMemberRefs.current;

    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-inViewport');
        } else {
          // entry.target.classList.remove('is-inViewport');
          // A DECOMMENTER POUR AVOIR LES ANIMATIONS A L'INFINI
        }
      });
    }, options);

    elements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    // Classe principale de la page Your Team Heroics
    <div className="your-team">
      {/* // Classe header comprenant l'image de fond */}
      <div className="header">
        <img src={`${process.env.PUBLIC_URL}/images/bg_pages/your_team_heroics.jpg`}  className="background-image" alt="background_image" />
      </div>

      {/* // Contenu de la page */}
      <div className="content-team">

        {/* // Section de description de la page en haut */}
        <div className='section-top'>
          <h2 className="section-title font-bold">Your Team <span class="highlight">Heroics</span></h2>
          <p className="section-description">
            At Heroics Capital Monaco, we are proud of our team of highly qualified and passionate professionals, each bringing a wealth of experience and expertise in their respective fields. Our dedicated team is the backbone of our firm, driving our mission of excellence and commitment to delivering superior financial solutions to our clients.
            <br/><br/>
            Discover the profiles of our key members, whose collective knowledge and unwavering dedication contribute to our success in the financial markets. Their commitment and passion not only propel Heroics Capital Monaco forward but also inspire confidence and trust in our valued clients.
          </p>
        </div>

        <div className="central-image-container">
          <img src={`${process.env.PUBLIC_URL}/images/team_photos/founders.jpg`} alt="Team Heroics" className="central-image" />
          <div className="hotspots">
            {[
              { top: '65%', left: '18%', name: 'Julien BAPTISTE', role: 'CEO & Partner Heroics Capital Monaco' },
              { top: '57%', left: '37%', name: 'Jonathan NIDDAM', role: 'Head of Research, Trading & Co-Founder' },
              { top: '50%', left: '58%', name: 'Alexandre TRAMINI', role: 'Head of Distribution & Co-Founder' },
              { top: '55%', left: '85%', name: 'Cyrille NAHABEDIAN', role: 'CEO Heroics Capital Partners & Co-Founder' },
            ].map((hotspot, index) => (
              <div
                key={index}
                className="hotspot"
                style={{ top: hotspot.top, left: hotspot.left }}
              >
                <div className="tooltip">
                  <strong className="tooltip-name">{hotspot.name}</strong>
                  <span className="tooltip-role">{hotspot.role}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* // Membres de l'équipe : team-member-container pour une photo à gauche et team-member-container reverse pour droite */}
        {/* // Julien BAPTISTE */}
        <div className="team-member-container">
          <div className="photo" ref={el => (teamMemberRefs.current[0] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/julien_baptiste.jpg`} alt="Julien BAPTISTE" />
          </div>

          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[1] = el)}>Julien BAPTISTE</h2>

            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[2] = el)}>CEO & Partner Heroics Capital Monaco</h3>

            <p className="member-description">
            After a career as a high-level athlete in martial arts, Julien began his career in financial markets in Luxembourg on a derivatives and structured products desk. He holds a Master's degree in Market Finance and Financial Engineering from Kedge Business School.
              <br/>He joined the private banking indusrty in Monaco at CFM Indosuez after 5 years in investment banking. His last position involved the responsibility of the Capital Markets Solutions and Active Advisory department.

              <br/><br/>At the end of 2023, with 13 years of experience in the banking sector, he decided to fully dedicate himself to the creation of Heroics Capital Monaco, thus realizing his entrepreneurial ambition.
              <br/><br/>His ambition at Heroics Capital Monaco: "To introduce a new business model in the Principality's investment advisory sector, a model where managers and clients align their interests with the objective of growing their capital in partnership with the management company."
            </p>
          </div>
        </div>

        {/* // Cyrille NAHABEDIAN */}
        <div className="team-member-container reverse">
          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[3] = el)}>Cyrille NAHABEDIAN</h2>

            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[4] = el)}>CEO Heroics Capital Partners & Co-Founder</h3>

            <p className="member-description">
            Cyrille Nahabedian is the Co-Founder and Chief Executive Officer at Heroics Capital Partners. With nearly 20 years of experience in European capital markets, Cyrille oversees the organization’s overall activities. He ensures the implementation of the strategy and reports key outcomes to the shareholders. As CEO, he co-validates the company’s investment decisions and recommendations, and actively engages with key clients. He also supervises the main recruitment and talent development programs within the organization.
              <br/><br/>Before founding Heroics Capital in January 2023, Cyrille held executive positions at Crédit Agricole IWM Group, notably as Global Head of Capital Markets Division, where he managed the global capital markets strategy and projects. He also led trading teams across Europe, specializing in derivatives.
              <br/><br/>A graduate of Skema Business School with a Master’s in Capital Markets Management and holding a Research Master's in Capital Markets from Paris I Sorbonne University, Cyrille continues to share his expertise as a lecturer for Professional Master’s programs at the Ecole Supérieure de Commerce de Paris.
            </p>
          </div>

          <div className="photo" ref={el => (teamMemberRefs.current[5] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/cyrille_nahabedian.jpg`} alt="Cyrille NAHABEDIAN" />
          </div>
        </div>

        {/* // Alexandre TRAMINI */}
        <div className="team-member-container">
          <div className="photo" ref={el => (teamMemberRefs.current[6] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/alexandre_tramini.jpg`} alt="Alexandre TRAMINI" />
          </div>

          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[7] = el)}>Alexandre TRAMINI</h2>

            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[8] = el)}>Head of Distribution & Co-Founder</h3>

            <p className="member-description">
              As a Co-Founder and Partner at Heroics Capital, Alexandre Tramini harnesses his finance expertise and entrepreneurial spirit to empower innovative startups and social enterprises. With over 17 years of experience in investment banking and wealth management, he has honed strong skills in corporate finance and capital markets, along with a profound understanding of the needs and challenges faced by high-net-worth clients and family offices.
              <br/><br/>Previously, Alexandre served as the European Head of Capital Markets Solutions Sales at CA Indosuez Wealth Management, where he led a team of sales professionals and advised clients on hedging and investment strategies across various asset classes. From 2014 to 2023, in collaboration with his partners, he launched a groundbreaking capital markets initiative within CA Indosuez Wealth Management, specializing in trading and advisory services. This initiative revitalized the private banking offering, addressing the unique needs of entrepreneurs, family offices, and UHNWIs while enhancing synergies with investment banking solutions.
              <br/><br/>Alexandre graduated from Kedge Business School and holds two postgraduate degrees: a Master’s in Financial Engineering and a Master’s in Banking and Wealth Law.
            </p>
          </div>
        </div>

        {/* // Jonathan NIDDAM */}
        <div className="team-member-container reverse">
          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[9] = el)}>Jonathan NIDDAM</h2>

            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[10] = el)}>Head of Research, Trading & Co-Founder</h3>
            
            <p className="member-description">
            Jonathan Niddam is the Co-Founder and Head of Research and Trading at Heroics Capital in Luxembourg. With over a decade of experience in finance, particularly in FX and stock options trading, he advises UHNW investors across markets such as precious metals, FX, equity, and fixed income, while managing alternative portfolios.
              <br/><br/>He leads Heroics Capital’s Research and Trading, applying advanced data techniques to enhance investment strategies. His nearly ten-year tenure at Indosuez Wealth Management, specializing in OTC derivatives and technology integration, has shaped his approach to portfolio and risk management.
              <br/><br/>Jonathan holds a degree in Computational Finance from EFREI Paris and gained experience at Morgan Stanley, refining his skills in equity derivatives. His expertise allows him to deliver sophisticated, customized solutions that keep Heroics Capital's strategies agile, data-driven, and innovative.
            </p>
          </div>

          <div className="photo" ref={el => (teamMemberRefs.current[11] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/jonathan_niddam.jpg`} alt="Jonathan NIDDAM" />
          </div>
        </div>

        {/* // Jean-Jacques MARTINENGHI */}
        <div className="team-member-container">
          <div className="photo" ref={el => (teamMemberRefs.current[12] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/jean_jacques_martinenghi.jpg`} alt="Jean-Jacques MARTINENGHI" />
          </div>

          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[13] = el)}>Jean-Jacques MARTINENGHI</h2>
            
            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[14] = el)}>Head Of Risk and Compliance</h3>
            
            <p className="member-description">
            Jean-Jacques Martinenghi joined Heroics Capital Monaco as Head of Risks and Compliance. In this role, he is responsible for overseeing risk governance and compliance, ensuring the company’s activities align with both local and international regulations. Jean-Jacques supervises the development and optimization of risk management processes, implementing effective strategies to identify, assess, and mitigate potential risks. He also ensures the rigorous application of internal policies, maintaining strict adherence to the legal and regulatory standards of the industry.
              <br/><br/>Before joining Heroics Capital Monaco, Jean-Jacques gained valuable experience at LCL’s General Inspection, where he spent 3 years auditing and analyzing internal processes, deepening his understanding of compliance and risk management issues within the financial and banking sector.
              <br/><br/>A graduate of ECE Paris as an engineer specialized in quantitative engineering, Jean-Jacques combines an analytical and proactive approach to safeguard the company’s operations while enhancing its compliance performance.
            </p>
          </div>
        </div>

        {/* // Tom CHEBEAUX */}
        <div className="team-member-container reverse">
          <div className="text">
            <h2 className="member-name font-semibold" ref={el => (teamMemberRefs.current[15] = el)}>Tom CHEBEAUX</h2>
            <h3 className="member-function font-semibold" ref={el => (teamMemberRefs.current[16] = el)}>Cross Asset Sales Trader</h3>
            <p className="member-description">
            Tom Chebeaux has joined Heroics Capital Monaco as a Cross-Asset Sales-Trader. In this role, he assists high-net-worth clients in building and managing their portfolios. He also collaborates with a diverse range of clients, such as asset managers, private banks, and wealth management advisors, in creating customized investment solutions.
              <br/><br/>Before joining Heroics Capital, he gained valuable experience at ODDO BHF CIB and CFM Indosuez Wealth Management, working in Equity Sales as well as Cross-Asset Sales & Structuring.
              <br/><br/>A graduate of EDHEC Business School and holding a Master’s in Finance from ESSEC Business School, Tom is passionate about finance. He stands out for his ability to understand the specific needs of his clients and provide them with perfectly tailored solutions.
            </p>
          </div>

          <div className="photo" ref={el => (teamMemberRefs.current[17] = el)}>
            <img src={`${process.env.PUBLIC_URL}/images/team_photos/tom_chebeaux.jpeg`} alt="Tom CHEBEAUX" />
          </div>
        </div>
      </div>

      {/* // Pre-footer avec vidéo en fond de logo */}
      <div className="bottom-section">
        <div className="logo_mask" style={logoMaskStyle}>
          <div className="video_holder">
            <video width="500" height="350" src="/videos/your_team_heroics.mp4" autoPlay muted loop playsInline />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default YourTeamHeroics;
