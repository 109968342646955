import React from 'react';
import '../css/OurLocations.css';
import Footer from '../footer';



const OurLocations = () => {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
      };
      
    return (
        // Classe principale de la page Locations
        <div className="locations">
            {/* // Cartes contenant chacun des locations*/}
            <div class="card-container">
                <div class='card'>
                    <div class='card-content'>
                        <div class='card-title font-bold'><h3>Monaco</h3></div>
                        <p> Le Panorama, 57 rue Grimaldi, 98000 Monaco</p>
                    </div>

                    <img src={`${process.env.PUBLIC_URL}/images/locations_photos/monaco.jpg`} alt="Card"/>
                </div>

                <div class='card'>
                    <div class='card-content'>
                        <div class='card-title font-bold'><h3>Luxembourg</h3></div>
                        <p>34A rue Philippe II, L-2340 Luxembourg City</p>
                    </div>
                    <img src={`${process.env.PUBLIC_URL}/images/locations_photos/luxembourg.jpg`} alt="Card"/>
                </div>
            </div>
                    

            {/* // Pre-footer avec vidéo en fond de logo */}
            <div className="bottom-section">
                <div class="logo_mask" style={logoMaskStyle}>
                    <div class="video_holder">
                        <video width="500px" height="350px" src="/videos/locations.mp4" autoPlay muted loop playsInline />
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default OurLocations;
