import React, { useEffect, useRef, useState } from 'react';
import '../css/InvestmentSolutions.css';
import Footer from '../footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const InvestmentSolutions = () => {
    const [activeSection, setActiveSection] = useState(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // arrows est par défaut true, nous le laissons tel quel pour le slider "Risk Management"
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: false,
                    dots: false,
                },
            },
        ],
    };
    
    // Paramètres pour le slider des sections sans les flèches
    const settingsSections = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false, // Supprime les flèches
        responsive: [
            {
                breakpoint: 600,
                settings: 'unslick', // Désactive le slider en dessous de 600px
            },
        ],
    };

    // Styles pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto',
    };

    const valuesRefs = useRef([]);

    // Observer pour les animations au scroll
    useEffect(() => {
        const elements = valuesRefs.current;

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-inViewport');
                } else {
                    // entry.target.classList.remove('is-inViewport');
                    // Décommentez la ligne ci-dessus pour que l'animation se répète
                }
            });
        }, options);

        elements.forEach((element) => {
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            elements.forEach((element) => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, [activeSection]); // Le useEffect se réexécute lorsque activeSection change

    // Fonction pour gérer le clic sur un bouton de section
    const handleSectionClick = (section) => {
        setActiveSection(section);
        // Faire défiler jusqu'à la section
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Données pour les sections
    const sectionsData = [
        {
            name: 'Cash Management',
            description: 'Optimize your cash management with tailored treasury solutions.',
            key: 'treasury-management',
        },
        {
            name: 'Portfolio Analysis',
            description: 'Consolidate your positions and aggregate all of your financial risks.',
            key: 'portfolio-analysis',
        },
        {
            name: 'Risk Management',
            description: 'Effectively manage and mitigate risks with our robust framework.',
            key: 'risk-management',
        },
    ];

    return (
        <div className="invest-solutions">
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"
            ></link>
            {/* En-tête avec image de fond */}
            <div className="header">
                <img
                    src={`${process.env.PUBLIC_URL}/images/bg_pages/investment_solutions.jpg`}
                    className="background-image"
                    alt="background_image"
                />
            </div>

            {/* Section Advisory Services */}
            <div className="advisory-container">
                <img
                    src={`${process.env.PUBLIC_URL}/images/invest_photos/advisory_services.jpg`}
                    alt="Advisory services"
                    ref={(el) => (valuesRefs.current[0] = el)}
                />

                <div className="textblock-advisory">
                    <div
                        className="title font-bold"
                        ref={(el) => (valuesRefs.current[1] = el)}
                    >
                        <h1>
                            Advisory <span className="highlight">Services 3.0</span>
                        </h1>
                    </div>
                    <h4 className="sub-title font-semibold">
                        Your capital growth, the heart of our strategy.
                    </h4>

                    <p>
                        Gain access to our dedicated and experienced investment advisors, who are
                        committed to generating alpha while taking into account your unique risk
                        appetite. Our advisory team provides personalized guidance and strategic
                        insights to help you achieve your financial objectives. By closely monitoring
                        market trends and leveraging their deep industry knowledge, our advisors
                        ensure that your investment strategy remains agile and effective. At Heroics
                        Capital Monaco, our personal goals, we work diligently to optimize your
                        portfolio and drive long-term success.
                    </p>
                </div>
            </div>

            {/* Cartes de la section Advisory */}
            <div className="card-container">
                {/* Carte Advisory by Heroics */}
                <div className="card">
                    <div className="card-content">
                        <div className="card-title font-semibold">
                            <h3>Advisory by Heroics</h3>
                        </div>
                        <p>
                            <b>Success fee</b> approach with our experts aligned on capital growth.
                            <br />
                            <br />
                            Only pay when your investment pays off.
                        </p>
                    </div>

                    <img
                        src={`${process.env.PUBLIC_URL}/images/invest_photos/advisory_by_heroics.jpg`}
                        alt="Advisory by heroics"
                    />
                </div>

                {/* Carte Expert Advice */}
                <div className="card">
                    <div className="card-content">
                        <div className="card-title font-semibold">
                            <h3>Expert Advice</h3>
                        </div>
                        <p>
                            Leverage the knowledge and experience of seasoned investment
                            professionals.
                        </p>
                    </div>

                    <img
                        src={`${process.env.PUBLIC_URL}/images/invest_photos/expert_advice.jpg`}
                        alt="Expert advice"
                    />
                </div>

                {/* Carte Tailored Strategies */}
                <div className="card">
                    <div className="card-content">
                        <div className="card-title font-semibold">
                            <h3>Tailored Strategies</h3>
                        </div>
                        <p>
                            Receive customized recommendations based on your specific risk profile
                            and investment goals.
                        </p>
                    </div>

                    <img
                        src={`${process.env.PUBLIC_URL}/images/invest_photos/tailored_strategies.jpg`}
                        alt="Tailored strategies"
                    />
                </div>

                {/* Carte Alpha Generation */}
                <div className="card">
                    <div className="card-content">
                        <div className="card-title font-semibold">
                            <h3>Alpha Generation</h3>
                        </div>
                        <p>
                            Focus on strategies designed to outperform the market and achieve
                            superior returns.
                        </p>
                    </div>

                    <img
                        src={`${process.env.PUBLIC_URL}/images/invest_photos/alpha_generation.jpg`}
                        alt="Alpha generation"
                    />
                </div>
            </div>

            {/* Boutons pour afficher les sections supplémentaires */}
            <div className="w-3/4 m-auto">
                <div className="mt-20">
                    <Slider {...settingsSections}>
                        {sectionsData.map((section, index) => (
                            <div
                                key={index}
                                className="bg-grey h-[150px] text-black rounded-xl border-black cursor-pointer"
                                onClick={() => handleSectionClick(section.key)}
                            >
                                <div className="flex flex-col justify-center items-center text-center gap-4 p-4">
                                    <p className="text-xl font-semibold bg-[#061237] text-white px-3 py-3 rounded-xl">
                                        {section.name}
                                    </p>
                                    <p>{section.description}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

            {/* Sections conditionnelles basées sur le bouton cliqué */}
            {activeSection === 'treasury-management' && (
                <div id="treasury-management" className="treasury-management-container">
                    {/* En-tête de la section */}
                    <div className="treasury-management-header">
                        <div
                            className="treasury-management-title font-bold"
                            ref={(el) => (valuesRefs.current[2] = el)}
                        >
                            <h1>
                                Cash <span className="highlight">Management</span>
                            </h1>
                        </div>

                        <div
                            className="treasury-management-text"
                            ref={(el) => (valuesRefs.current[3] = el)}
                        >
                            <p>
                                Optimize your cash management with our tailored treasury solutions.
                                We provide strategic guidance and innovative tools to ensure
                                efficient liquidity management, enhancing your ability to meet
                                financial obligations and capitalize on investment opportunities.
                            </p>
                        </div>
                    </div>

                    {/* Section Structured Products */}
                    <div className="treasury-management-sp-container">
                        <div className="treasury-management-sp-textblock">
                            <div className="title font-semibold">
                                <h2 ref={(el) => (valuesRefs.current[4] = el)}>
                                    Structured products, tailored to your success
                                </h2>
                            </div>
                            <h4 className="sub-title font-semibold">
                                Explore the power of structured products with Heroics Capital Monaco,
                                and let us help you achieve your financial aspirations.
                            </h4>
                            <p>
                                Whether you're seeking capital protection, income generation, or
                                exposure to specific market sectors, our structured products can be
                                customized to meet your needs. With the flexibility to combine
                                different asset classes and the liquidity access to top tier 1
                                investment banks, structured products allow you to tap into market
                                opportunities that traditional investments may overlook.
                                <br />
                                Our team of engineers is committed to helping you navigate the
                                complexities of structured products, ensuring your understanding of
                                the risks and benefits. We work closely with you to design a solution
                                that aligns with your investment objectives, offering transparency,
                                innovation, and performance. Invest with confidence.
                            </p>
                        </div>

                        <img
                            src={`${process.env.PUBLIC_URL}/images/invest_photos/structured_products.jpg`}
                            alt="Structured products"
                            ref={(el) => (valuesRefs.current[5] = el)}
                        />
                    </div>

                    {/* Section AMC */}
                    <div className="treasury-management-amc-container">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/invest_photos/amc.jpg`}
                            alt="AMC"
                            ref={(el) => (valuesRefs.current[6] = el)}
                        />

                        <div className="treasury-management-amc-textblock">
                            <div className="title font-semibold">
                                <h2 ref={(el) => (valuesRefs.current[7] = el)}>
                                    The power of active management,
                                    <br /> customized for you
                                </h2>
                            </div>
                            <p>
                                Gain access to a customized portfolio that is actively managed by our
                                team of seasoned professionals, allowing you to capitalize on market
                                opportunities as they arise. Actively Managed Certificates offer
                                unparalleled flexibility, enabling you to invest in a wide range of
                                asset classes, including equities, bonds, commodities, and
                                alternative investments. This flexibility, combined with our active
                                management approach, ensures that your portfolio can adapt to
                                changing market conditions, helping to optimize returns while
                                managing risk. Transparency is at the heart of our AMCs. You'll have
                                full visibility into the underlying assets and the strategy employed,
                                giving you confidence in how your investments are being managed.
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {activeSection === 'portfolio-analysis' && (
                <div id="portfolio-analysis" className="portfolio-analysis-container">
                    {/* En-tête de la section */}
                    <div className="portfolio-analysis-header">
                        <div
                            className="title font-bold"
                            ref={(el) => (valuesRefs.current[8] = el)}
                        >
                            <h1>
                                Portfolio <span className="highlight">Analysis</span>
                            </h1>
                        </div>

                        <div className="text" ref={(el) => (valuesRefs.current[9] = el)}>
                            <p>
                                Benefit from Heroics Capital's expertise to consolidate your
                                positions and aggregate all of your financial risks using smart
                                digital tools and advanced technology. Maintaining a global overview
                                of your entire portfolio at any time is essential for sustaining an
                                optimal investment dynamic.
                            </p>
                        </div>
                    </div>

                    {/* Cartes des avantages */}
                    <div className="portfolio-analysis-services">
                        <div
                            className="row"
                            ref={(el) => (valuesRefs.current[10] = el)}
                        >
                            <div className="service">
                                <h2>Comprehensive Analysis</h2>
                                <p>
                                    Consolidate all your financial positions for a holistic view of
                                    your portfolio.
                                </p>
                            </div>

                            <div className="service">
                                <h2>Advanced Technology</h2>
                                <p>
                                    Utilize cutting-edge digital tools for accurate and efficient
                                    risk aggregation.
                                </p>
                            </div>

                            <div className="service">
                                <h2>Continuous Monitoring</h2>
                                <p>
                                    Ensure real-time oversight of your investments to maintain the
                                    best possible investment strategy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {activeSection === 'risk-management' && (
                <div id="risk-management" className="risk-management-container">
                    {/* En-tête de la section */}
                    <div className="risk-management-header">
                        <div
                            className="title font-bold"
                            ref={(el) => (valuesRefs.current[11] = el)}
                        >
                            <h1>
                                Risk <span className="highlight">Management</span>
                            </h1>
                        </div>

                        <div className="text" ref={(el) => (valuesRefs.current[12] = el)}>
                            <p>
                                Effectively manage and mitigate risks with our robust risk
                                management framework. Our approach combines rigorous analysis,
                                innovative tools, and proactive strategies to protect your
                                investments from market volatility and unforeseen events.
                            </p>
                        </div>
                    </div>

                    <div
                        className="w-3/4 m-auto"
                        ref={(el) => (valuesRefs.current[13] = el)}
                    >
                        <div className="mt-20">
                            <Slider {...settings}>
                                {data.map((d, index) => (
                                    <div
                                        key={index}
                                        className="bg-grey h-[150px] text-black rounded-xl border-black"
                                    >
                                        <div className="flex flex-col justify-center items-center text-center gap-4 p-4">
                                            <p className="text-xl font-semibold bg-[#061237] text-white px-3 py-3 rounded-xl">
                                                {d.name}
                                            </p>
                                            <p>{d.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>

                    {/* Cartes des avantages */}
                    <div className="risk-management-services">
                        <div
                            className="row"
                            ref={(el) => (valuesRefs.current[14] = el)}
                        >
                            <div className="service">
                                <h2>Proactive Strategies</h2>
                                <p>
                                    Implement forward-thinking strategies to anticipate and mitigate
                                    potential risks.
                                </p>
                            </div>

                            <div className="service">
                                <h2>Advanced Analytics</h2>
                                <p>
                                    Use sophisticated tools and models to analyze and manage risk
                                    exposure.
                                </p>
                            </div>

                            <div className="service">
                                <h2>Continuous Oversight</h2>
                                <p>
                                    Maintain ongoing vigilance over your investments to ensure
                                    resilience against market fluctuations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Section finale avec le logo et la vidéo */}
            <div className="bottom-section">
                <div className="logo_mask" style={logoMaskStyle}>
                    <div className="video_holder">
                        <video
                            width="500px"
                            height="350px"
                            src="/videos/investment_solutions.mp4"
                            autoPlay
                            muted
                            loop
                            playsInline
                        />
                    </div>
                </div>
            </div>

            <Footer />
            <script src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js"></script>
        </div>
    );
};

const data = [
    {
        name: 'Liquidity Risk',
        description: "Permanent measure of each instrument's liquidity",
        img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`,
    },
    {
        name: 'Concentration Risk',
        description: 'Balanced approach and thresholds definition',
        img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`,
    },
    {
        name: 'Valuation Risk',
        description: 'Via most performing valuation tools',
        img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`,
    },
    {
        name: 'Variation Risk',
        description: 'Via hedging processes',
        img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`,
    },
    {
        name: 'Leverage Risk',
        description: 'Collateral Management upon ISDA SIMM regulatory methodology',
        img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`,
    },
    {
        name: 'Credit Risk',
        description: 'Counterparties & investments diversification',
        img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`,
    },
];

export default InvestmentSolutions;
