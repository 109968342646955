import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './css/HeroSection.css';
import '../App.css';

function HeroSection() {
    const videoRef = useRef(null);
    const nextSectionRef = useRef(null);
    const [language, setLanguage] = useState('en');
    const location = useLocation();

    useEffect(() => {
        setLanguage(location.pathname.startsWith('/fr') ? 'fr' : 'en');
    }, [location.pathname]);


    const handleScrollToNextSection = () => {
        
        if (nextSectionRef.current) {
            nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const texts = {
        en: {
            learnMore: "Learn More"
        },
        fr: {
            learnMore: "En savoir plus"
        }
    };

    return (
        <div>
            {/* Video de fond */}
            <div 
                className="hero-container" 
                style={{ height: '100vh', position: 'relative' }}
            >
                <video 
                    id="homeVideo" 
                    ref={videoRef} 
                    src="/videos/herosection.mp4" 
                    autoPlay 
                    muted 
                    loop
                    playsInline
                />
                <div className="overlay"></div>
               {/* Bouton de scroll */}
                <section id="learn-more" className="demo" onClick={handleScrollToNextSection}>
                    <h1 className="font-semibold text-xl" >{texts[language].learnMore}</h1>
                    <a><span></span></a>
                </section>

            </div>
            {/* Section suivante video pour scroll en dessous de la vidéo */}
            <div ref={nextSectionRef}>
            </div>
        </div>
    );
}

export default HeroSection;
