import React, { useEffect, useRef } from 'react'
import '../css/AboutUs.css';
import Footer from '../footer';



const AboutUs = () => {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
      };

      const valuesRefs = useRef([]);
  
      // Code permettant de faire apparaitre les éléments au scroll
  useEffect(() => {
    
    const elements = valuesRefs.current;

    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-inViewport');
        } else {
        // entry.target.classList.remove('is-inViewport');
          // A DECOMMENTER POUR AVOIR LES ANIMATIONS A L'INFINI
        }
      });
    }, options);

    elements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div className="about-us">
        <div className="header">
            <img src={`${process.env.PUBLIC_URL}/images/bg_pages/about_us.jpg`} className="background-image" alt="image de fond" />
        </div>
        
        <div className='content'>
          <div className='section-top'>
            <h2 className="section-title font-semibold"><br/>L'histoire de <span class="highlight">Heroics Capital Monaco</span></h2>
            <p className="section-description">
              <i>“Chez Heroics, nous croyons que la gestion discrétionnaire 3.0 et le conseil remplaceront les modèles traditionnels en tirant parti de l’efficacité rendue possible par la digitalisation des outils. Nous créons un club de partenaires, un cercle privilégié d’entrepreneurs passionnés qui s’enrichissent et évoluent ensemble. Nous souhaitons donner vie à notre gestion pour nos investisseurs. Comme eux, nous portons humblement la passion des résultats et du dépassement de soi. Comme eux, nous restons curieux, suivons les tendances, nous adaptons aux nouvelles évolutions, innovons ensemble, et gardons le goût de l’apprentissage et du renouveau.”.</i>
              <br/>
              <b>Cyrille NAHABEDIAN - Co-Fondateur & CEO Heroics Capital Group</b>
              <br/><br/>
              Chez Heroics Capital Monaco, nous avons débuté avec une ambition simple : transformer le conseil en investissement. Animés par notre passion et notre expertise, nous cherchons constamment l’excellence. À chaque étape, nos succès ont construit l’identité et la confiance que nous témoignent nos clients.
              <br/><br/>
              Notre fondation repose sur trois valeurs fondamentales : une <b class='text-[#3972E5]'>vision</b> qui nous guide, une <b class='text-[#3972E5]'>mission</b> qui nous motive, et une <b class='text-[#3972E5]'>ambition</b> qui nous propulse vers l'avant.
            </p>
          </div>
        </div>

        <div class='container-right' ref={el => (valuesRefs.current[0] = el)}>
            <div class='textblock-right'>
                <h1 class='title-right font-semibold'>Une Vision</h1>
                <h4 class='sub-title-right font-semibold'>Un point de vue unique sur l'investissement et le conseil.</h4>
                <p>Notre <b>vision</b> est de faire croître le capital de nos investisseurs en adoptant des stratégies de gestion et de conseil innovantes, conçues pour s'adapter aux dynamiques complexes des marchés financiers. Nous cherchons à offrir des performances décorrélées des fluctuations traditionnelles du marché, permettant ainsi à nos clients de bénéficier d’un portefeuille diversifié, robuste et résilient face aux aléas économiques.</p>
                <p>
                Notre objectif est de créer de la valeur durable et de bâtir des relations de confiance à long terme avec nos clients. En anticipant les cycles de marché et en naviguant avec agilité à travers la volatilité, nous visons à maximiser les rendements tout en assurant une croissance stable et sécurisée des actifs de nos investisseurs.
                </p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/images/about_photos/a_vision.jpg`} alt="Salle de marché"/>
        </div>

        <div class='container-left' ref={el => (valuesRefs.current[1] = el)}>
          <img src={`${process.env.PUBLIC_URL}/images/about_photos/a_mission.jpg`} alt="Salle de marché"/>
          <div class='textblock-left'>
              <h1 class='title-left font-semibold'>Une Mission</h1>

              <h4 class='sub-title-left font-semibold'>Fournir des stratégies innovantes pour une croissance financière décorrélée.</h4>

              <p>
              Notre <b>mission</b> est de dynamiser la croissance du capital de nos investisseurs en élaborant des stratégies de gestion alternatives qui tirent parti des opportunités émergentes et des dynamiques de marché les plus prometteuses. Nous concevons des solutions d’investissement sur mesure, robustes et agiles, adaptées aux objectifs et aux contraintes de chaque client.</p>
              <p>
              Nous privilégions une communication proactive, en fournissant des rapports détaillés et des mises à jour en temps réel, afin que nos clients puissent suivre l’évolution de leur portefeuille en toute transparence. En les impliquant activement dans les décisions stratégiques, nous bâtissons un partenariat solide, fondé sur l’échange, la réactivité et une confiance mutuelle qui va au-delà des performances financières.
              </p>
          </div>
        </div>

        <div class='container-last' ref={el => (valuesRefs.current[2] = el)}>
          <img src={`${process.env.PUBLIC_URL}/images/about_photos/an_ambition.jpg`} alt="Une Ambition"/>
          <div class='textblock-last'>
              <h1 class='title-last font-semibold'>Une Ambition</h1>

              <h4 class='sub-title-last font-semibold'>Notre succès, votre performance</h4>

              <p>
              Notre <b>ambition</b> est de créer un cercle d’investisseurs privilégiés qui collaborent pour s’élever mutuellement et maximiser les opportunités de croissance. Cette implication active garantit qu’ils restent informés et engagés, favorisant ainsi une relation de confiance et de collaboration.</p>
              <p>
              En parallèle, nous cultivons des relations durables où nos intérêts sont profondément alignés avec ceux de nos clients. Chez Heroics, nous croyons fermement que notre succès dépend du leur, c'est pourquoi nous avons choisi un modèle de rémunération basé sur la performance.
              </p>
              <p>
              Cette approche nous pousse à viser l’excellence, à dépasser les attentes, et à créer des partenariats solides et durables où la réussite de nos clients devient notre propre réussite.
              </p>
          </div>
        </div>

        <div className="bottom-section">
            <div class="logo_mask" style={logoMaskStyle}>
                <div class="video_holder">
                    <video width="500px" height="350px" src="/videos/about_us.mp4" autoPlay muted loop playsInline />
                </div>
            </div>
        </div>
    <Footer/>

    </div>
);
};

export default AboutUs;
