import React, { useEffect, useRef } from 'react'
import '../css/AlternativeSolutions.css';
import Footer from '../footer';


const AlternativeSolutions = () => {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
    };

    const valuesRefs = useRef([]);
    
    // Code permettant de faire apparaitre les éléments au scroll
    useEffect(() => {
    
    const elements = valuesRefs.current;

    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-inViewport');
        } else {
        //   entry.target.classList.remove('is-inViewport'); 
        // A DECOMMENTER SI ON VEUT QUE LES ANIMATIONS SE REDECLENCHE A CHAQUE FOIS QU'ON SCROLL
        }
      });
    }, options);

    elements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

    return (
        // Classe principale de la page Alternative Solutions
        <div className="alternative-solutions">
            {/* // Classe header comprenant l'image de fond */}
            <div className="header">
                <img src={`${process.env.PUBLIC_URL}/images/bg_pages/alternative_solutions.jpg`}className="background-image" alt="background_image" />
            </div>
            
            {/* // Première partie sur les open-end funds */}
            <div className='open-end-fund'>
                {/* // Header comprenant le titre stylisée ainsi que le premier texte à gauche */}
                <div className='open-end-fund-header'>
                    <div class='text-open-end-fund'>
                        <p><b>Liquid and innovative financial instruments</b>, providing strong agility in response to market conditions.</p>
                        <p><b>Multi-asset strategies</b>, designed to mitigate risk through diversification, ensuring a balanced portfolio that can withstand various market dynamics.</p>
                    </div>
                    
                    <div class='title-open-end-fund font-bold' ref={el => (valuesRefs.current[0] = el)}> <h1>Multi-Asset <br/><span class="highlight">Alternative Investment Funds</span></h1></div>
                </div>
                
                {/* // Partie du bas de la partie avec l'image et les avantages */}
                <div className='open-end-fund-bottom'>
                    <img src={`${process.env.PUBLIC_URL}/images/alter_photos/multi_asset_alternative_investment_funds.jpg`} alt="Multi-asset alternative investment funds"/>
                    
                    <div class='text-open-end-fund'>
                        <p><u><b>Agility</b></u>: Use liquid financial instruments for quick adaptability.</p>
                        <p> <u><b>Diversification</b></u>: Deploy multi-asset strategies to spread risk.</p>
                        <p><u><b>Innovation</b></u>: Access cutting-edge financial tools and techniques.</p>
                        <p> <br></br><br></br>Our dedicated team continuously monitors and adjusts the portfolio to extract optimal performance, ensuring that your investments grow even in volatile markets.</p>
                    </div>
                </div>
            </div>
            
            {/* // Deuxième partie sur les fonds dédiés */}
            <div className='dedicated-fund'>
                {/* // Header avec le titre stylisée ainsi que le texte descriptif */}
                <div class='dedicated-fund-header'>
                    <div class='title-dedicated-fund font-bold' ref={el => (valuesRefs.current[1] = el)}> <h1>Dedicated <span class="highlight">Funds</span></h1></div>

                    <div class='text-dedicated-fund' ref={el => (valuesRefs.current[2] = el)}>
                        <p>
                        Design your own Luxembourgish Alternative Investment Fund with our expert assistance. 
                        We offer bespoke solutions, allowing you to deploy a strategy tailored to your specific investment goals. 
                        Managed by our proficient quantitative and research team, your dedicated fund will benefit from our extensive market knowledge and advanced analytical capabilities.
                        </p>
                    </div>
                </div>

                <div class='container-dedicated-fund text-4xl'>
                    <h1>Why Choose a Dedicated Fund ?</h1>
                    <div class='row'>
                        <div class='service'>
                            <h2>Customization</h2>
                            <p>Develop a fund that aligns perfectly with your investment strategy.</p>
                        </div>

                        <div class='service'>
                            <h2>Expert Management</h2>
                            <p>Our quantitative and research team ensures rigorous analysis and strategic oversight.</p>
                        </div>

                        <div class='service'>
                            <h2>Luxembourg Advantage</h2>
                            <p>Benefit from the robust regulatory framework and favorable investment environment of Luxembourg.</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* // Titre de la troisème partie sur les stratégies et les classes d'actifs */}
            <div class='title-cards font-bold' ref={el => (valuesRefs.current[3] = el)}> <h1>Strategies and <span class="highlight">Asset Classes</span></h1></div>

            {/* // Conteneur des cartes pour chacune des parties */}
            <div class="card-container">
                <div class='card'>
                    <div class='card-content'>
                        <div class='card-title font-semibold'><h3>Systematic Options Strategies</h3></div>
                        <p> Consistent and reliable returns.</p>
                    </div>

                    <img src={`${process.env.PUBLIC_URL}/images/alter_photos/systematic_options_strategies.jpg`} alt="Systematic options strategies"/>
                </div>

                <div class='card'>
                    <div class='card-content'>
                        <div class='card-title font-semibold'><h3>Exotic Options Strategies</h3></div>
                        <p> Innovative approaches for unique market opportunities.</p>
                    </div>

                    <img src={`${process.env.PUBLIC_URL}/images/alter_photos/exotic_options_strategies.jpg`} alt="Exotic options strategies"/>
                </div>

                <div class='card'>
                    <div class='card-content'>
                        <div class='card-title font-semibold'><h3>Use of all listed & OTC markets</h3></div>
                        <p> Maximizing potential with comprehensive market access.</p>
                    </div>

                    <img src={`${process.env.PUBLIC_URL}/images/alter_photos/use_of_all_listed_&_otc_markets.jpg`} alt="Use of all listed & OTC markets"/>
                </div>

                <div class='card'>
                    <div class='card-content'>
                        <div class='card-title font-semibold'><h3>Leverage Optimization</h3></div>
                        <p> Enhancing gains through strategic leverage.</p>
                    </div>

                    <img src={`${process.env.PUBLIC_URL}/images/alter_photos/leverage_optimization.jpg`} alt="Leverage optimization"/>
                </div>
            </div>

           {/* // Pre-footer avec vidéo en fond de logo */}
            <div className="bottom-section">
                <div class="logo_mask" style={logoMaskStyle}>
                    <div class="video_holder">
                        <video width="500px" height="350px" src="/videos/alternative_solutions.mp4" autoPlay muted loop playsInline />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AlternativeSolutions;
