import React, { useEffect, useRef } from 'react'
import '../css/AlternativeSolutions.css';
import Footer from '../footer';


const AlternativeSolutions = () => {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
    };

    const valuesRefs = useRef([]);
    
    // Code permettant de faire apparaitre les éléments au scroll
    useEffect(() => {
    
    const elements = valuesRefs.current;

    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-inViewport');
        } else {
        //   entry.target.classList.remove('is-inViewport'); 
        // A DECOMMENTER SI ON VEUT QUE LES ANIMATIONS SE REDECLENCHE A CHAQUE FOIS QU'ON SCROLL
        }
      });
    }, options);

    elements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div className="alternative-solutions">
        <div className="header">
            <img src={`${process.env.PUBLIC_URL}/images/bg_pages/alternative_solutions.jpg`} className="background-image" alt="image de fond" />
        </div>

        <div className='open-end-fund'>
            <div className='open-end-fund-header'>
                <div class='text-open-end-fund'>
                    <p><b>Instruments financiers liquides et innovants</b>, offrant une forte agilité en réponse aux conditions du marché.</p>
                    <p><b>Stratégies multi-actifs</b>, conçues pour atténuer les risques grâce à la diversification, assurant un portefeuille équilibré capable de résister à diverses dynamiques de marché.</p>
                </div>
                
                <div class='title-open-end-fund font-bold' ref={el => (valuesRefs.current[0] = el)}> <h1>Fonds d'Investissement Alternatifs <br/><span class="highlight">Multi-Actifs</span></h1></div>
            </div>
            
            <div className='open-end-fund-bottom'>
                <img src={`${process.env.PUBLIC_URL}/images/alter_photos/multi_asset_alternative_investment_funds.jpg`} alt="Fonds d'investissement alternatifs multi-actifs"/>
                
                <div class='text-open-end-fund'>
                    <p><u><b>Agilité</b></u> : Utilisation d'instruments financiers liquides pour une adaptabilité rapide.</p>
                    <p> <u><b>Diversification</b></u> : Déploiement de stratégies multi-actifs pour répartir les risques.</p>
                    <p><u><b>Innovation</b></u> : Accès à des outils et techniques financières de pointe.</p>
                    <p> <br></br><br></br>Notre équipe dédiée surveille et ajuste en permanence le portefeuille pour en extraire une performance optimale, garantissant que vos investissements croissent même sur des marchés volatils.</p>
                </div>
            </div>
        </div>
        
        <div className='dedicated-fund'>
            <div class='dedicated-fund-header'>
                <div class='title-dedicated-fund font-bold' ref={el => (valuesRefs.current[1] = el)}> <h1>Fonds <span class="highlight">Dédiés</span></h1></div>

                <div class='text-dedicated-fund' ref={el => (valuesRefs.current[2] = el)}>
                    <p>
                    Concevez votre propre Fonds d'Investissement Alternatif luxembourgeois avec notre assistance experte. 
                    Nous offrons des solutions sur mesure, vous permettant de déployer une stratégie adaptée à vos objectifs d'investissement spécifiques. 
                    Géré par notre équipe compétente de quantitatifs et de recherche, votre fonds dédié bénéficiera de notre vaste connaissance du marché et de nos capacités analytiques avancées.
                    </p>
                </div>
            </div>

            <div class='container-dedicated-fund text-4xl'>
                <h1>Pourquoi Choisir un Fonds Dédié ?</h1>
                <div class='row'>
                    <div class='service'>
                        <h2>Personnalisation</h2>
                        <p>Développez un fonds qui s'aligne parfaitement avec votre stratégie d'investissement.</p>
                    </div>

                    <div class='service'>
                        <h2>Gestion Experte</h2>
                        <p>Notre équipe de quantitatifs et de recherche assure une analyse rigoureuse et une supervision stratégique.</p>
                    </div>

                    <div class='service'>
                        <h2>Avantage Luxembourg</h2>
                        <p>Bénéficiez du cadre réglementaire solide et de l'environnement d'investissement favorable du Luxembourg.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class='title-cards font-bold' ref={el => (valuesRefs.current[3] = el)}> <h1>Stratégies et <span class="highlight">Classes d'Actifs</span></h1></div>

        <div class="card-container">
            <div class='card'>
                <div class='card-content'>
                    <div class='card-title font-semibold'><h3>Stratégies Systématiques sur Options</h3></div>
                    <br/>
                    <p> Rendements cohérents et fiables.</p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/alter_photos/systematic_options_strategies.jpg`} alt="Stratégies systématiques sur options"/>
            </div>

            <div class='card'>
                <div class='card-content'>
                    <div class='card-title font-semibold'><h3>Stratégies sur Options Exotiques</h3></div>
                    <p> Approches innovantes pour des opportunités de marché uniques.</p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/alter_photos/exotic_options_strategies.jpg`} alt="Stratégies sur options exotiques"/>
            </div>

            <div class='card'>
                <div class='card-content'>
                    <div class='card-title font-semibold'><h3>Utilisation de tous les marchés cotés & OTC</h3></div>
                    <p> Maximiser le potentiel avec un accès complet aux marchés.</p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/alter_photos/use_of_all_listed_&_otc_markets.jpg`} alt="Utilisation de tous les marchés cotés & OTC"/>
            </div>

            <div class='card'>
                <div class='card-content'>
                    <div class='card-title font-semibold'><h3>Optimisation de l'Effet de Levier</h3></div>
                    <p> Améliorer les gains grâce à un effet de levier stratégique.</p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/alter_photos/leverage_optimization.jpg`} alt="Optimisation de l'effet de levier"/>
            </div>
        </div>

       <div className="bottom-section">
            <div class="logo_mask" style={logoMaskStyle}>
                <div class="video_holder">
                    <video width="500px" height="350px" src="/videos/alternative_solutions.mp4" autoPlay muted loop playsInline />
                </div>
            </div>
        </div>
        <Footer />
    </div>
);
};

export default AlternativeSolutions;