import React from 'react';
import Navbar from './components/navbar.js';
import { BrowserRouter as Router, Routes, Route } from 
'react-router-dom';
import './App.css';
import './fonts.css';
import Home from './components/pages/Home';
import HomeFR from './components/pages/HomeFR';
import YourTeamHeroics from './components/pages/YourTeamHeroics';
import YourTeamHeroicsFR from './components/pages/YourTeamHeroicsFR';
import JoinUs from './components/pages/JoinUs';
import JoinUsFR from './components/pages/JoinUsFR';
import AboutUs from './components/pages/AboutUs.js';
import AboutUsFR from './components/pages/AboutUsFR';
import InvestmentSolutions from './components/pages/InvestmentSolutions.js';
import InvestmentSolutionsFR from './components/pages/InvestmentSolutionsFR';
import AlternativeSolutions from './components/pages/AlternativeSolutions.js';
import AlternativeSolutionsFR from './components/pages/AlternativeSolutionsFR';
import OurLocations from './components/pages/OurLocations.js';
import OurLocationsFR from './components/pages/OurLocationsFR';
import ScrollToTop from './ScrollToTop.js';



function App() {
  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        <Navbar/>
        <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about-us' element={<AboutUs/>} /> 
        <Route path='/your-team-heroics' element={<YourTeamHeroics/>} /> 
        <Route path='/investment-solutions' element={<InvestmentSolutions/>} />
        <Route path='/alternative-solutions' element={<AlternativeSolutions/>} />
        <Route path='/join-us' element={<JoinUs/>} /> 
        <Route path='/locations' element={<OurLocations/>} /> 

        <Route path='/fr/' element={<HomeFR/>} />
        <Route path='/fr/about-us' element={<AboutUsFR/>} /> 
        <Route path='/fr/your-team-heroics' element={<YourTeamHeroicsFR/>} /> 
        <Route path='/fr/investment-solutions' element={<InvestmentSolutionsFR/>} />
        <Route path='/fr/alternative-solutions' element={<AlternativeSolutionsFR/>} />
        <Route path='/fr/join-us' element={<JoinUsFR/>} /> 
        <Route path='/fr/locations' element={<OurLocationsFR/>} /> 
        </Routes>
        </Router>
      
    </div>
  );
}

export default App;
