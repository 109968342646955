import React, { useEffect, useRef } from 'react'
import '../css/AboutUs.css';
import Footer from '../footer';



const AboutUs = () => {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
      };

      const valuesRefs = useRef([]);
  
      // Code permettant de faire apparaitre les éléments au scroll
  useEffect(() => {
    
    const elements = valuesRefs.current;

    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-inViewport');
        } else {
        // entry.target.classList.remove('is-inViewport');
          // A DECOMMENTER POUR AVOIR LES ANIMATIONS A L'INFINI
        }
      });
    }, options);

    elements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

    return (
        // Classe principale de la page About Us
        <div className="about-us">
            {/* // Classe header comprenant l'image de fond */}
            <div className="header">
                <img src={`${process.env.PUBLIC_URL}/images/bg_pages/about_us.jpg`} className="background-image" alt="background_image" />
            </div>
            
            {/* // Contenu de la page */}
            <div className='content'>

              {/* // Section de description de la page en haut */}
              <div className='section-top'>
                <h2 className="section-title font-semibold"><br/><span class="highlight">Heroics Capital Monaco</span>, the story</h2>
                <p className="section-description">
                  <i>“We, At Heroics, believe that 3.0 discretionary management and advisory will replace traditional business models by leveraging the efficiency enabled by the digitalization of tools. We are creating a partner club, a privileged circle of passionate entrepreneurs who enrich and grow together. We aim to make our management come to life for our investors. Like them, we humbly carry the passion for results and self-improvement. Like them, we remain curious, follow trends, adapt to new developments, innovate together, and maintain a taste for learning and renewal”.</i>
                  <br/>
                  <b>Cyrille NAHABEDIAN - Co-Founder & CEO Heroics Capital Group</b>
                  <br/><br/>
                  At Heroics Capital Monaco, we started with a simple ambition: to transform investment advisory.
                  <br/>
                  Driven by our passion and expertise, we continually strive for excellence. Every step of our journey has built the identity and trust our clients place in us.
                  <br/><br/>
                  Our foundation is built upon three core values : a <b class='text-[#3972E5]'>vision</b> that guides us, a <b class='text-[#3972E5]'>mission</b> that drives us, and an <b class='text-[#3972E5]'>ambition</b> that propels us forward.
                </p>
              </div>
            </div>

            {/* // Section Vision */}
            <div class='container-right' ref={el => (valuesRefs.current[0] = el)}>
                <div class='textblock-right'>
                    <h1 class='title-right font-semibold'>A Vision</h1>
                    <h4 class='sub-title-right font-semibold'>Unique point of view of investing and advisory.</h4>
                    <p>Our <b>vision</b> is to grow our investors' capital assets using innovative management and advisory methods. 
                        By employing these unique strategies, we aim to generate performance that is uncorrelated with traditional financial markets, offering a diversified and robust investment portfolio.</p>
                    <p>
                    We are dedicated to delivering consistent value and fostering long-term relationships with our clients. 
                    Our approach seeks to achieve superior returns while navigating market volatility, ensuring stable and reliable growth for our investors' assets.
                    </p>
                </div>
                <img src={`${process.env.PUBLIC_URL}/images/about_photos/a_vision.jpg`} alt="Trading floor"/>
            </div>

            {/* // Section Mission */}
            <div class='container-left' ref={el => (valuesRefs.current[1] = el)}>
              <img src={`${process.env.PUBLIC_URL}/images/about_photos/a_mission.jpg`} alt="Trading floor"/>
              <div class='textblock-left'>
                  <h1 class='title-left font-semibold'>A Mission</h1>

                  <h4 class='sub-title-left font-semibold'>Delivering Innovative Strategies for Uncorrelated Financial Growth.</h4>

                  <p>
                  Our <b>mission</b> is to contribute to the growth of our investors' capital through an innovative alternative management approach. 
                  By generating performance uncorrelated with traditional markets, we provide a unique and robust investment strategy.</p>
                  <p>
                  We are dedicated to maintaining transparent and consistent communication with our investors. 
                  By offering real-time updates and actively involving them in the fund's daily management, we ensure they are always informed and engaged, fostering a strong and trustworthy relationship.
                  </p>
              </div>
            </div>

            {/* // Section Ambition */}
            <div class='container-last' ref={el => (valuesRefs.current[2] = el)}>
              <img src={`${process.env.PUBLIC_URL}/images/about_photos/an_ambition.jpg`} alt="An Ambition"/>
              <div class='textblock-last'>
                  <h1 class='title-last font-semibold'>An Ambition</h1>

                  <h4 class='sub-title-last font-semibold'>Your performance, our success</h4>

                  <p>
                  Our <b>ambition</b> is to create an exclusive network of investors who collaborate to uplift one another, unlocking and maximizing growth opportunities. Through active engagement, our members stay informed and involved, fostering a strong foundation of trust and collaboration.</p>
                  <p>
                  In parallel, we nurture long-term relationships where our interests are deeply aligned with those of our clients. At Heroics, we believe that our success is fundamentally connected to theirs, which is why we have embraced a performance-based compensation model.
                  </p>
                  <p>
                  This approach inspires us to pursue excellence, exceed expectations, and build enduring partnerships where our clients' achievements become our own.
                  </p>
              </div>
            </div>

            {/* // Pre-footer avec vidéo en fond de logo */}
            <div className="bottom-section">
                <div class="logo_mask" style={logoMaskStyle}>
                    <div class="video_holder">
                        <video width="500px" height="350px" src="/videos/about_us.mp4" autoPlay muted loop playsInline />
                    </div>
                </div>
            </div>
        <Footer/>

        </div>
    );
};

export default AboutUs;
