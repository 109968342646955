import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import './css/footer.css'; 

const Footer = () => {
  const [language, setLanguage] = useState('en');
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setLanguage(location.pathname.startsWith('/fr') ? 'fr' : 'en');
  }, [location.pathname]);

  const texts = {
    en: {
      home: "Home",
      aboutUs: "About Us",
      yourTeamHeroics: "Your Team Heroics",
      investmentSolutions: "Investment Solutions",
      alternativeSolutions: "Alternative Solutions",
      joinUs: "Join Us",
      locations: "Locations",
      termsConditions: "Terms & conditions",
      privacy: "Privacy",
      credit: "Credit"
    },
    fr: {
      home: "Accueil",
      aboutUs: "À propos de nous",
      yourTeamHeroics: "L'équipe Heroics",
      investmentSolutions: "Solutions d'investissement",
      alternativeSolutions: "Solutions alternatives",
      joinUs: "Rejoignez-nous",
      locations: "Localisations",
      termsConditions: "Conditions générales",
      privacy: "Confidentialité",
      credit: "Crédit"
    }
  };

  const getLocalizedPath = (path) => {
    return language === 'fr' ? `/fr${path}` : path;
  };

  return (
    <footer className="footer-distributed">
      <div className="footer-left">
      <img src={`${process.env.PUBLIC_URL}/images/logos/hc_blue.png`} alt="Logo" height='60px' width='200px' className="navbar-logo-image"/>
      <p className="footer-links">
          <Link to={getLocalizedPath('/')} className="link-1">{texts[language].home}</Link>
          <Link to={getLocalizedPath('/about-us')}>{texts[language].aboutUs}</Link>
          <Link to={getLocalizedPath('/your-team-heroics')}>{texts[language].yourTeamHeroics}</Link>
          <Link to={getLocalizedPath('/investment-solutions')}>{texts[language].investmentSolutions}</Link>
          <Link to={getLocalizedPath('/alternative-solutions')}>{texts[language].alternativeSolutions}</Link>
          <Link to={getLocalizedPath('/join-us')}>{texts[language].joinUs}</Link>
        </p>
        
        <p className="footer-company-name"> Heroics Capital © {currentYear}</p>
      </div>

      <div className="footer-center">
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <span><Link to={getLocalizedPath('/locations')}>{texts[language].locations}</Link></span>
         Le Panorama, 57 rue Grimaldi, 98000 Monaco
         <br/>
        34A rue Philippe II, L-2340 Luxembourg City
        </p>
        <div className="footer-icons flex justify-center mt-6">
          <a 
            href="https://heroics-capital.com/" 
            className="inline-block w-[35px] h-[35px] bg-[#E6E7EB] rounded-[2px] mr-[3px] mb-[5px] flex items-center justify-center"
          >
            <img 
              src={`${process.env.PUBLIC_URL}/images/logos/hc_mini_blue.png`} 
              alt="Heroics Capital" 
              className="w-[300px] h-[21px] object-contain mt-1"
            />
          </a>
          <a 
            href="https://www.linkedin.com/company/heroics-capital/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-block w-[35px] h-[35px] bg-[#E6E7EB] rounded-[2px] mr-[3px] mb-[5px] flex items-center justify-center text-[#061237]"
          >
            <FontAwesomeIcon icon={faLinkedin} className="text-[20px]" />
          </a>
        </div>
        <p className="footer-links-2 flex justify-center">
          <a href={`${process.env.PUBLIC_URL}/files/terms_&_conditions.pdf`} target="_blank" rel="noopener noreferrer">{texts[language].termsConditions}</a>
          <a href={`${process.env.PUBLIC_URL}/files/privacy.pdf`} target="_blank" rel="noopener noreferrer">{texts[language].privacy}</a>
          <a href="https://www.linkedin.com/in/gabriel-tseng-771a19201/" target="_blank" rel="noopener noreferrer">{texts[language].credit}</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
