import React from 'react';
import '../css/JoinUs.css';
import Footer from '../footer';



const JoinUs = () => {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
    };
      
    return (
        <div className="join-us"> 
            <div className="header">
                <img src={`${process.env.PUBLIC_URL}/images/bg_pages/join_us.jpg`} className="background-image" alt="image de fond" />
            </div>
            
            <div className="container">
                <div className="main-content">
                    <section className="section">
                        <h2 className="section-title font-bold">Rejoignez Heroics Capital </h2><br/>
                        
                        <h5 className="section-text">
                            Chez Heroics, nous valorisons l'innovation, la collaboration et la passion pour la finance. Nous croyons en la création d'un environnement où des individus talentueux peuvent s'épanouir et faire la différence.
                            <br/><br/> Que vous soyez un professionnel expérimenté ou que vous débutiez votre carrière, nous accueillons les candidatures spontanées d'individus enthousiastes à l'égard de la finance et désireux de contribuer à notre environnement de startup dynamique.
                            <br/><br/>Nous avons hâte d'avoir de vos nouvelles et potentiellement de vous accueillir dans notre équipe ! 
                            <br/>
                            <br/>
                        </h5>

                        <h5 className="section-text">
                            De plus, vous pouvez nous contacter sur LinkedIn et nous envoyer un message direct. Visitez notre page LinkedIn
                            ici pour rester informé des nouvelles de l'entreprise et des opportunités d'emploi.
                        </h5>
                    </section>
                </div>
                
                <div className="sidebar">
                    <section className="section contact-form">
                        <h3 className="contact-title font-semibold">Contactez-nous</h3><br/>

                        <h4 className="contact-info">
                            Le Panorama<br/> 57 rue Grimaldi <br/> 98000 Monaco <br/> <b>hr@heroics-capital.com</b>
                        </h4>
                        <br/>
                        <br/>

                        <img  
                            className="logoh block mx-auto" 
                            src={`${process.env.PUBLIC_URL}/images/logos/hc_mini_blue.png`}  
                            width='200px' 
                            alt="Logo"
                        />
                        
                        <h6 className="font-semibold">"Unusual actions or achievements, far greater than expected."</h6>
                    </section>
                </div>

            </div>

            <div className="bottom-section">
                <div class="logo_mask" style={logoMaskStyle}>
                    <div class="video_holder">
                        <video width="500px" height="350px" src="/videos/join_us.mp4" autoPlay muted loop playsInline />
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default JoinUs;