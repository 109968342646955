import React from "react";
import "../../App.css";
import HeroSection from "../herosection.js";
import Footer from '../footer';
import '../css/Home.css';

function Home() {
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
    };

    return (
        <>
                {/* Vidéo de la page */}
                <HeroSection />

                {/* Contenu de la page */}
                <div className="content-home">

                {/* Cartes de redirection */}
                <div class="card-container">

                    {/* About Us */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>About Us</h3></div>
                            <p> The essence of HEROICS DNA.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/about-us'>
                            <p class="hover-underline-animation">About our identity →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/about_us.jpg`} alt="About us"/>
                            </a>
                        </div>
                    </div>
                    
                    {/* Your Team */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Your Team Heroics</h3></div>
                            <p> The team to your service.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/your-team-heroics'>
                            <p class="hover-underline-animation">About our group →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/your_team.jpg`} alt="Your Team"/>
                            </a>
                        </div>
                    </div>
                    
                    {/* Investment solutions */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Investment solutions</h3></div>
                            <p> Cutting edge investment strategies.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/investment-solutions'>
                            <p class="hover-underline-animation">About our investment solutions →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/investment_solutions.jpg`} alt="Investment solutions"/>
                            </a>
                        </div>
                    </div>

                    {/* Alternative solutions */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Alternative solutions</h3></div>
                            <p> Explore innovative pathways.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/alternative-solutions'>
                            <p class="hover-underline-animation">About our alternatives solutions →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/alternative_solutions.jpg`} alt="Alternatives solutions"/>
                            </a>
                        </div>
                    </div>

                    {/* Join Us */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Join Us</h3></div>
                            <p>Join the family.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/join-us'>
                            <p class="hover-underline-animation">About our opportunities →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/join_us.jpg`} alt="Join Us"/>
                            </a>
                        </div>
                    </div>

                    {/* Locations */}
                    <div class='card'>
                        <div class='card-content'>
                            <div class='card-title font-semibold text-[#061237]'><h3>Locations</h3></div>
                            <p> Our global presence.</p>
                        </div>
                        <div class='card-clickable'>
                            <a href='/locations'>
                            <p class="hover-underline-animation">About our presence →</p>
                            <img src={`${process.env.PUBLIC_URL}/images/home_photos/locations.jpg`} alt="Locations"/>
                            </a>
                        </div>
                    </div>
                </div>
            
            
            {/* // Pre-footer avec vidéo en fond de logo */}
            <div className="bottom-section">
                <div className="logo_mask" style={logoMaskStyle}>
                    <div className="video_holder">
                        <video width="500px" height="350px" src="/videos/home.mp4" autoPlay muted loop playsInline />
                    </div>
                </div>
            </div>
            <Footer />
            </div>
        </>
    );
}

export default Home;
