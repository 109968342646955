import '../css/InvestmentSolutions.css';
import Footer from '../footer';
import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const InvestmentSolutions = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };
    // Code CSS pour le masque du logo
    const logoMaskStyle = {
        display: 'inline-block',
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        maskImage: `url(${process.env.PUBLIC_URL}/images/logos/hcm_white.png)`,
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        margin: '0 auto'
      };

      const valuesRefs = useRef([]);

    // Code permettant de faire apparaitre les éléments au scroll
    useEffect(() => {
    
    const elements = valuesRefs.current;

    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-inViewport');
        } else {
        //   entry.target.classList.remove('is-inViewport');
        // A DECOMMENTER POUR AVOIR LES ANIMATIONS A L'INFINI
        }
      });
    }, options);

    elements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div className="invest-solutions">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"></link>
        <div className="header">
            <img src={`${process.env.PUBLIC_URL}/images/bg_pages/investment_solutions.jpg`} className="background-image" alt="image de fond" />
        </div>

        <div class='advisory-container'>
            <img src={`${process.env.PUBLIC_URL}/images/invest_photos/advisory_services.jpg`} alt="Services de conseil" ref={el => (valuesRefs.current[0] = el)}/>

            <div class='textblock-advisory'>
                <div class='title font-bold' ref={el => (valuesRefs.current[1] = el)}> <h1>Advisory <span class="highlight">Services 3.0 </span></h1></div>
                <div class='sub-title'><i>La croissance de votre capital au cœur de notre stratégie.</i></div>
                <p>
                    Accédez à nos experts dédiés et expérimentés. Nous nous engageons à générer de l’alpha tout en tenant compte de votre appétit pour le risque. Nous optimisons votre stratégie d’investissement grâce à des outils de back-testing élaborés et la mise en place de scenarios de stress-test.
                    <br/>L’objectif est d’ajuster la résilience de votre portefeuille quel que soit le contexte sur les marchés financiers. 
                    <br/><br/>Chez Heroics Capital Monaco, agilité et efficacité sont au service de la performance de nos investisseurs.
                </p>
            </div>
        </div>

        <div class="card-container">
            <div class='card'>
                <div class='card-content'>
                    <div class='card-title font-semibold'><h3>Conseil par Heroics</h3></div>
                    <p>Une rémunération basée sur la <b>Success Fee</b>. <br/><br/>Only pa when your investment pays off.</p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/invest_photos/advisory_by_heroics.jpg`} alt="Conseil par Heroics"/>
            </div>

            <div class='card'>
                <div class='card-content'>
                    <div class='card-title font-semibold'><h3>Conseil Expert</h3></div>
                    <p>Tirez parti des connaissances et de l'expérience de professionnels chevronnés de l'investissement.</p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/invest_photos/expert_advice.jpg`} alt="Conseil expert"/>
            </div>
            
            <div class='card'>
                <div class='card-content'>
                    <div class='card-title font-semibold'><h3>Stratégies Sur Mesure</h3></div>
                    <p>Recevez des recommandations personnalisées basées sur votre profil de risque spécifique et vos objectifs d'investissement.</p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/invest_photos/tailored_strategies.jpg`} alt="Stratégies sur mesure"/>
            </div>
            
            <div class='card'>
                <div class='card-content'>
                    <div class='card-title font-semibold'><h3>Génération d'Alpha</h3></div>
                    <p>Accédez à des stratégies conçues pour générer de la performance quel que soit les conditions de marché.</p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/invest_photos/alpha_generation.jpg`} alt="Génération d'alpha"/>
            </div>
        </div>

        <div className='treasury-management-container'>
            <div class='treasury-management-header'>
                <div class='treasury-management-title font-bold' ref={el => (valuesRefs.current[2] = el)}> <h1>Gestion de <span class="highlight">Trésorerie</span></h1></div>
                
                <div class='treasury-management-text' ref={el => (valuesRefs.current[3] = el)}>
                    <p>
                        Optimisez votre gestion de trésorerie. Nous assurons une gestion agile et efficace de la liquidité en gardant pour objectif votre capacité à faire face aux obligations financières.
                        Capitalisez sur nos opportunités d’investissement.
                    </p>
                </div>
            </div>

            <div className='treasury-management-sp-container'>
                <div className='treasury-management-sp-textblock'>
                    <div class='title font-semibold'> <h2>Produits structurés, adaptés à votre succès</h2></div>
                    <div class='sub-title'><i>Découvrez la gestion agile de produit structurés au service de la réalisation de vos aspirations financières.</i></div>
                    <p>
                    Que vous recherchiez une protection du capital, une génération de revenus ou une exposition à des secteurs de marché spécifiques, nos produits structurés peuvent être personnalisés pour répondre à vos besoins.
                    Avec la flexibilité de combiner différentes classes d'actifs et l'accès à la liquidité des banques d'investissement de premier rang, les produits structurés vous permettent de saisir des opportunités de marché que les investissements traditionnels pourraient négliger. 
                    <br/>Notre équipe d'ingénieurs s'engage à vous aider à naviguer dans les complexités des produits structurés, en vous assurant de comprendre les risques et les avantages. Nous travaillons en étroite collaboration avec vous pour concevoir une solution qui s'aligne sur vos objectifs d'investissement, offrant transparence, innovation et performance.
                    </p>
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/invest_photos/structured_products.jpg`} alt="Produits structurés" ref={el => (valuesRefs.current[4] = el)}/>
            </div>
            
            <div className='treasury-management-amc-container'>
                <img src={`${process.env.PUBLIC_URL}/images/invest_photos/amc.jpg`} alt="AMC" ref={el => (valuesRefs.current[5] = el)}/>

                <div className='treasury-management-amc-textblock'>
                    <div class='title font-semibold'> <h2>La gestion active,<br/> au service de la performance</h2></div>
                    <p>
                        Bénéficiez d'un portefeuille sur mesure, géré activement par notre équipe de professionnels expérimentés, vous permettant de saisir les opportunités du marché au moment opportun.
                        Participez aux choix stratégiques de gestion et accédez en temps réel à nos experts.
                        Nos Certificats de Gestion Active offrent une flexibilité inégalée, vous permettant d'investir dans une large gamme de classes d'actifs. Cette flexibilité, associée à notre approche de gestion active, garantit l’adaptabilité de votre portefeuille aux conditions de marché en constante évolution, optimisant ainsi les rendements tout en maîtrisant les risques. La transparence est au cœur de nos certificats. Vous bénéficiez d'une visibilité totale sur les actifs sous-jacents et la stratégie employée.
                    </p>
                </div>
            </div>
        </div>

        <div className='portfolio-analysis-container'>
            <div class='portfolio-analysis-header'>
                <div class='title font-bold' ref={el => (valuesRefs.current[6] = el)}> <h1>Analyse de <span class="highlight">Portefeuille</span></h1></div>
                
                <div class='text' ref={el => (valuesRefs.current[7] = el)}>
                    <p>
                    Bénéficiez de l'expertise d'Heroics Capital pour consolider vos positions et agréger tous vos risques financiers en utilisant des outils numériques intelligents et une technologie avancée.
                    Maintenir une vue d'ensemble globale de l'ensemble de votre portefeuille à tout moment est essentiel pour maintenir une dynamique d'investissement optimale.
                    </p>
                </div>
            </div>

            <div class='portfolio-analysis-services'>
                <div class='row'>
                    <div class='service '>
                        <h2>Analyse Complète</h2>
                        <p>Consolidez toutes vos positions financières pour une vue holistique de votre portefeuille.</p>
                    </div>

                    <div class='service'>
                        <h2>Technologie Avancée</h2>
                        <p>Utilisez des outils numériques de pointe pour une agrégation précise et efficace des risques.</p>
                    </div>

                    <div class='service'>
                        <h2>Surveillance Continue</h2>
                        <p>Assurez une surveillance en temps réel de vos investissements pour maintenir la meilleure stratégie d'investissement possible.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='risk-management-container'>
            <div class='risk-management-header'>
            <div class='text' ref={el => (valuesRefs.current[9] = el)}>
                    <p>
                        Gérez activement les risques de votre portefeuille grâce à notre plateforme personnalisée.
                        Notre approche combine une analyse rigoureuse, des outils innovants et des stratégies proactives pour protéger vos investissements contre la volatilité du marché et les événements imprévus.
                    </p>
                </div>
                
                <div class='title font-bold' ref={el => (valuesRefs.current[8] = el)}> <h1>Gestion des <span class="highlight">Risques</span></h1></div>
                
            </div>

            <div className='w-3/4 m-auto'>
            <div className='mt-20'>
                <Slider {...settings}>
                {data.map((d) => (
                    <div className="bg-grey h-[150px] text-black rounded-xl  border-black"> 
                    <div className='flex flex-col justify-center items-center text-center gap-4 p-4'>
                        <p className="text-xl font-semibold bg-[#061237] text-white px-3 py-3 rounded-xl">{d.name}</p>
                        <p >{d.description}</p>
                    </div>
                    </div>
                ))}
                </Slider>
            </div>
        </div>

            <div class='risk-management-services'>
                <div class='row'>
                    <div class='service'>
                        <h2>Stratégies Proactives</h2>
                        <p>Mettez en œuvre des stratégies de couverture pour anticiper et atténuer les risques potentiels.</p>
                    </div>

                    <div class='service'>
                        <h2>Analyses Avancées</h2>
                        <p>Utilisez des outils et des modèles sophistiqués pour analyser et gérer l'exposition au risque.</p>
                    </div>

                    <div class='service'>
                        <h2>Surveillance Continue</h2>
                        <p>Maintenez une vigilance constante sur vos investissements pour assurer la résilience face aux fluctuations du marché.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="bottom-section">
            <div class="logo_mask" style={logoMaskStyle}>
                <div class="video_holder">
                    <video width="500px" height="350px" src="/videos/investment_solutions.mp4" autoPlay muted loop playsInline />
                </div>
            </div>
        </div>
        <Footer />
        <script src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js"></script>
    </div>
);
};

const data = [
{
    name:'Risque de Liquidité',
    description : "Mesure permanente de la liquidité de chaque instrument",
    img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`
},
{
    name:'Risque de Concentration',
    description : "Approche équilibrée et définition des seuils",
    img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`
},
{
    name:"Risque d'Évaluation",
    description : "Via les outils d'évaluation les plus performants",
    img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`
},
{
    name:'Risque de Variation',
    description : "Via des processus de couverture",
    img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`
},
{
    name:'Risque de Levier',
    description : "Gestion du collatéral selon la méthodologie réglementaire ISDA SIMM",
    img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`
},
{
    name:'Risque de Crédit',
    description : "Diversification des contreparties et des investissements",
    img: `${process.env.PUBLIC_URL}/images/invest_photos/risk_management.png`
}
];
export default InvestmentSolutions;